const state = {
    chatStats: {},
    chatDetail: {
        name: ''
    },
    chatMessages: [],
    chatUnfinished: {}
};
const getters = {
    chatStats: state => state.chatStats,
    chatDetail: state => state.chatDetail,
    chatMessages: state => state.chatMessages,
    chatUnfinished: state => state.chatUnfinished
};

const actions = {
   
    async chatStats({ commit }, payload) {
        let response = await window.axios.post(`/api/chats/stats`,{
            selectedDate: payload.selectedDate
        })
        commit("setChatStats", response.data);        
    },

    async chatDetail({ commit }, payload) {
        let response = await window.axios.get(`/api/chats/${payload.id}`)
        let detail = response.data.data
        commit("setChatDetail", detail);        
    },
    async adminStartChat({commit}, payload) {
        let response = await window.axios.post(`/api/chats/start`, payload)
        let detail = response.data.data;
        commit("setChatDetail", detail);
    },
    async adminUnfinishedChat({commit}, payload) {
        let response = await window.axios.get(`/api/chats/unfinished`)
        let detail = response.data.data;
        commit("setChatUnfinished", detail);
    },
    async adminSubmitChat({commit}, payload) {
        let response = await window.axios.post(`/api/chats/${payload.chat_id}/submit`, {
            message: payload.message
        })
        commit('addChatMessage',response.data.data);

    },
    async addChat({commit}, payload){
        commit('addChatMessage',payload.data);
    },
    async adminEndChat({commit}, payload) {
        await window.axios.post(`/api/chats/${payload.chat_id}/end`)
        commit("deleteChat");
        commit("deleteUnfinishedChat");

    },
    async deleteChat({commit}, payload){
        commit("deleteChat");
        commit("deleteUnfinishedChat");
    }
};

const mutations = {
    setChatStats(state, payload){
        state.chatStats = {...payload}
    },
    setChatDetail(state, payload) {
        state.chatDetail = payload
        state.chatMessages = [...payload.messages]
    },
    setChatUnfinished(state, payload){
        state.chatUnfinished = payload
    },
    deleteUnfinishedChat(state){
        state.chatUnfinished = {};
    },
    deleteChat(state) {
        state.chatDetail = {}
        state.chatMessages = []
    },
    addChatMessage(state, payload){
        state.chatMessages.push(payload)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
