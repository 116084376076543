import { codePointAt } from "core-js/fn/string";

const state = {
    chatMessages: [],
    chatInfo: {},
    guestInfo: {},
};
const getters = {
    chatMessages: state => state.chatMessages,
    chatInfo: state => state.chatInfo,
    guestInfo: state => state.guestInfo
};

const actions = {
   
    async chatDetail({ commit }, payload) {
        let response = await window.axios.get(`/api/chats/${payload.id}`)
        let detail = response.data.data
        commit("setChatDetail", detail);        
    },
    async chatList({commit}, payload) {
        await window.axios.get(`/api/chats/${payload.id}`)
    },
    async publicRegisterGuest({ commit }, payload){
        let response = await window.axios.post(`/api/public-register`, payload.data)
        commit("setGuestInfo",response.data.data);
    },
    async publicCheckGuest({commit}, payload){
        let response = await window.axios.get(`/api/public-check-register`);
        commit("setGuestInfo",response.data.data);
    },
    async addChat({commit}, payload) {
        commit("addChatMessage",payload.data)
    },
    async submitChat({ commit }, payload) {
        let response = await window.axios.post(`/api/public-chats/${payload.guestKey}/send`,payload.data);
        commit("addChatMessage",response.data.data);
    },
    async endChat({commit}, payload) {
        await window.axios.get(`/api/public-chats/${payload.guestKey}/end`);
        commit("deleteChat");
    },
    async deleteChat({commit}, payload){
        commit("deleteChat");
    },
    async setDirectGuestInfo({commit}, payload){
        commit("setGuestInfo",payload.data);
    },

    async updateLastOnline({commit}, payload){
        await window.axios.post(`/api/public-chats/${payload.guestKey}/last-online`);
    }
};

const mutations = {
    deleteChat(state) {
        state.chatMessages = []
        state.chatInfo = {}
        state.guestInfo = {}
    },
    addChatMessage(state, payload){
        state.chatMessages.push(payload)
    },
    setGuestInfo(state, payload){
        if(payload){
            state.guestInfo = { ...payload}
            if(payload.chat){
                state.chatInfo = {...payload.chat} 
                if(payload.chat.messages){
                    state.chatMessages = [...payload.chat.messages];
                }
            }
            
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
