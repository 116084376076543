<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="fas fa-tachometer-alt" :to="{ name: 'dashboard'}" :exact="true">
        {{ $t('sidebar.dashboard') }}
      </sidenav-router-link>

      <sidenav-router-link icon="far fa-comments" :to="{ name : 'guest-list'}" :exact="true">
        Guests
      </sidenav-router-link>

      <sidenav-divider class="mb-1" />
      <div
        v-if="
                    roles.includes('Admin') ||
                        ability.can('read', 'User') ||
                        ability.can('update', 'User') ||
                        ability.can('create', 'User') ||
                        ability.can('delete', 'User')
                "
      >
        <sidenav-header class="small font-weight-semibold">
          {{ $t('sidebar.administration') }}
        </sidenav-header>
        
        <sidenav-menu
          icon="ion ion-ios-people"
          :active="isMenuActive('/admin/user')"
          :open="isMenuOpen('/admin/user')"
          v-if="
                        roles.includes('Admin') ||
                            ability.can('read', 'User') ||
                            ability.can('update', 'User')
                    "
        >
          <template slot="link-text">
            {{ $t('sidebar.users') }}
          </template>
          <sidenav-router-link
            :to="{ name: 'user-list' }"
            :exact="true"
            v-if="ability.can('read', 'User')"
          >
            {{ $t('sidebar.view-users') }}
          </sidenav-router-link>
          <sidenav-router-link
            :to="{ name: 'user-create' }"
            :exact="true"
            v-if="ability.can('create', 'User')"
          >
            {{ $t('sidebar.add-users') }}
          </sidenav-router-link>
        </sidenav-menu>

         <sidenav-menu
          icon="fas fa-question-circle"
          :active="isMenuActive('/admin/question')"
          :open="isMenuOpen('/admin/question')"
          v-if="
                        roles.includes('Admin') ||
                            ability.can('read', 'Question') ||
                            ability.can('update', 'Question')
                    "
        >
          <template slot="link-text">
            {{ $t('sidebar.questions') }}
          </template>
          <sidenav-router-link
            to="/admin/questions"
            :exact="true"
            v-if="ability.can('read', 'Question')"
          >
            {{ $t('sidebar.view-questions') }}
          </sidenav-router-link>
          <sidenav-router-link
            :to="{ name: 'question-create' }"
            :exact="true"
            v-if="ability.can('create', 'Question')"
          >
            {{ $t('sidebar.add-questions') }}
          </sidenav-router-link>

      
          
        </sidenav-menu>

      
      </div>
    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavDivider
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    Sidenav,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavDivider
  },

  props: {
    orientation: {
      type: String,
      default: "vertical"
    }
  },

  created() {},

  computed: {
    p() {
      return localStorage.getItem("website_p");
    },
    roles() {
      return this.$store.getters['auth/roles'];
    },
    ability() {
      return window.ability;
    },
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    }
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    }
  }
};
</script>
