<template>
    <b-navbar
        toggleable="lg"
        :variant="getLayoutNavbarBg()"
        class="layout-navbar align-items-lg-center container-p-x"
    >
        <!-- Brand -->
        <b-navbar-brand :to="{ name: 'dashboard'}"><img :src="logo" height="40"/></b-navbar-brand>

        <!-- Sidenav toggle -->
        <b-navbar-nav
            class="align-items-lg-center mr-auto mr-lg-4"
            v-if="sidenavToggle"
        >
            <a
                class="nav-item nav-link px-0 ml-2 ml-lg-0"
                href="javascript:void(0)"
                @click="toggleSidenav"
            >
                <i class="ion ion-md-menu text-large align-middle" />
            </a>
        </b-navbar-nav>

        <!-- Navbar toggle -->
        <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

        <b-collapse is-nav id="app-layout-navbar">
            <!-- Divider -->
            <hr class="d-lg-none w-100 my-2" />

            <b-navbar-nav class="align-items-lg-center ml-auto">
                <!-- Divider -->
                <div
                    class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
                >
                    |
                </div>

                <b-nav-item-dropdown :right="!isRTL" class="demo-navbar-user">
                    <template slot="button-content">
                        <span
                            class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
                        >
                            <span v-if="user.picture_path == null">
                                <i class="fa fa-user-circle fa-2x"></i>
                            </span>
                            <img
                                v-else
                                :src="user.picture_path"
                                alt
                                class="d-block ui-w-30 rounded-circle"
                            />
                            <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
                                `${user.name}`
                            }}</span>
                        </span>
                    </template>

                    <b-dd-item @click.prevent="toProfile">
                        <i class="ion ion-ios-person text-lightest"></i>
                            &nbsp;<span class="text-primary">
                            {{ $t('navbar.my-profile') }}
                        </span>
                    </b-dd-item>
                    <!--<b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item>-->
                    <!--<b-dd-item><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</b-dd-item>-->
                    <!-- <b-dd-divider /> -->
                    <b-dd-item @click.prevent="logout">
                        <i class="ion ion-ios-log-out text-danger"></i> &nbsp;
                        {{ $t('navbar.logout') }}
                    </b-dd-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>

        <!-- Notification Pop Up -->
        <b-modal
            centered
            id="notification-modal"
            ref="notification-modal"
            header-text-variant="dark"
            body-text-variant="dark"
            :title="notif.title"
            hide-footer
        >
            <div v-html="notif.description" class="text-dark"></div>
        </b-modal>
    </b-navbar>
</template>

<script>
import moment from "moment";

export default {
    name: "app-layout-navbar",

    props: {
        sidenavToggle: {
            type: Boolean,
            default: true
        }
    },

    data: function() {
        return {
            first_name: localStorage.getItem("first_name"),
            last_name: localStorage.getItem("last_name"),
            notifications: [],
            notif: {
                id: null,
                title: null,
                description: null,
                createdAt: null
            },
            unreadNotification: 0
        };
    },

    computed: {
        logo: function() {
            return "/images/childinc-logo.png";
        },
        user: function(){
            return this.$store.getters['auth/user']
        }
    },

    methods: {
        secondsToHms(d) {
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor((d % 3600) / 60);
            var s = Math.floor((d % 3600) % 60);

            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay =
                m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            return hDisplay + mDisplay + sDisplay;
        },
        moment: function(mydate) {
            return moment(mydate);
        },

        toggleSidenav() {
            this.layoutHelpers.toggleCollapsed();
        },

        getLayoutNavbarBg() {
            return this.layoutNavbarBg;
        },

        logout() {
            this.$store.dispatch("auth/logout")
        },
        toProfile() {
            this.$router.push({ name: "profile" });
        }
    }
};
</script>

<style>
.item {
    position: relative;
    text-align: center;
    vertical-align: middle;
}
.notify-badge {
    position: absolute;
    right: -20%;
    top: -1%;
    background: red;
    text-align: center;
    border-radius: 50%;
    color: white;
    padding: 0px 6px;
    border: 1px solid;
    font-size: 12px;
}
</style>
