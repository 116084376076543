var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.loading
        ? _c("BlockUI", { attrs: { message: "", html: _vm.html } })
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", {
        on: { showLoader: _vm.showLoader, hideLoader: _vm.hideLoader }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }