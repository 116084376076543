var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "layout-navbar align-items-lg-center container-p-x",
      attrs: { toggleable: "lg", variant: _vm.getLayoutNavbarBg() }
    },
    [
      _c("b-navbar-brand", { attrs: { to: { name: "dashboard" } } }, [
        _c("img", { attrs: { src: _vm.logo, height: "40" } })
      ]),
      _vm._v(" "),
      _vm.sidenavToggle
        ? _c(
            "b-navbar-nav",
            { staticClass: "align-items-lg-center mr-auto mr-lg-4" },
            [
              _c(
                "a",
                {
                  staticClass: "nav-item nav-link px-0 ml-2 ml-lg-0",
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.toggleSidenav }
                },
                [
                  _c("i", {
                    staticClass: "ion ion-md-menu text-large align-middle"
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-navbar-toggle", { attrs: { target: "app-layout-navbar" } }),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "app-layout-navbar" } },
        [
          _c("hr", { staticClass: "d-lg-none w-100 my-2" }),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "align-items-lg-center ml-auto" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
                },
                [_vm._v("\n                |\n            ")]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "demo-navbar-user",
                  attrs: { right: !_vm.isRTL }
                },
                [
                  _c("template", { slot: "button-content" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-inline-flex flex-lg-row-reverse align-items-center align-middle"
                      },
                      [
                        _vm.user.picture_path == null
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-user-circle fa-2x"
                              })
                            ])
                          : _c("img", {
                              staticClass: "d-block ui-w-30 rounded-circle",
                              attrs: { src: _vm.user.picture_path, alt: "" }
                            }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "px-1 mr-lg-2 ml-2 ml-lg-0" },
                          [_vm._v(_vm._s("" + _vm.user.name))]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-dd-item",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toProfile($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "ion ion-ios-person text-lightest"
                      }),
                      _vm._v("\n                         "),
                      _c("span", { staticClass: "text-primary" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("navbar.my-profile")) +
                            "\n                    "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dd-item",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "ion ion-ios-log-out text-danger"
                      }),
                      _vm._v(
                        "  \n                    " +
                          _vm._s(_vm.$t("navbar.logout")) +
                          "\n                "
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "notification-modal",
          attrs: {
            centered: "",
            id: "notification-modal",
            "header-text-variant": "dark",
            "body-text-variant": "dark",
            title: _vm.notif.title,
            "hide-footer": ""
          }
        },
        [
          _c("div", {
            staticClass: "text-dark",
            domProps: { innerHTML: _vm._s(_vm.notif.description) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }