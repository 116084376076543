var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "layout-footer footer", class: _vm.getLayoutFooterBg() },
    [
      _c(
        "div",
        { staticClass: "container-fluid container-p-x pb-3" },
        [
          _c("b-row", [
            _c(
              "div",
              {
                staticClass: "col-12 col-md-7 pt-3 text-center text-md-left",
                staticStyle: { "font-size": "12px" }
              },
              [_vm._v("\n              ⓒ 2020 Child, Inc.\n          ")]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }