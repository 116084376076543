<template>
  <div class="layout-wrapper layout-1">
    <vue-snotify />

    <div class="layout-inner" v-if="user">
      <app-layout-navbar />

      <div class="layout-container">
        <app-layout-sidenav />

        <div class="layout-content">
          <div
            class="router-transitions container-fluid flex-grow-1 container-p-y"
          >
            <div
              class="alert alert-success"
              v-if="
                chatUnfinished &&
                chatUnfinished.guest &&
                $route.name != 'guest-chat'
              "
            >
              <h5 class="alert-heading">Unfinished chat</h5>
              You still have unfinished chat waiting.
              <b-link
                :to="{
                  name: 'guest-chat',
                  params: { id: chatUnfinished.guest.id },
                }"
                >Click here to go to chat page.</b-link
              >
            </div>
            <router-view />
          </div>

          <app-layout-footer />
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from "./LayoutFooter";
import { mapState } from "vuex";
import favicon from "favicon.js"

export default {
  name: "app-layout-1",
  components: {
    "app-layout-navbar": LayoutNavbar,
    "app-layout-sidenav": LayoutSidenav,
    "app-layout-footer": LayoutFooter,
  },

  data: () => ({
    audioMessage: new Audio("/audio/bell_effect.ogg"),
    selectedTaskId: null,
    loading: false,
    reminders: [],
    options: [],
  }),

  created() {
    let self = this;
    this.$store.dispatch("auth/profileInfo").then((result) => {
      let language =
        this.user.options.find((option) => option.option_name == "language") ||
        "en";
      self.$i18n.locale = language.option_value;
    });
    this.loadUnfinishedChat();
  },

  mounted() {
    this.layoutHelpers.init();
    this.layoutHelpers.update();
    this.layoutHelpers.setAutoUpdate(true);

    this.setupPresenceOnlineUsers();
    this.setupEchoListener();
  },

  destroyed() {
    this.resetEchoListener();
    this.resetPresenceOnlineUsers();
  },

  beforeDestroy() {
    this.layoutHelpers.destroy();
  },

  methods: {
     updateFavicon() {
      favicon.change(
        "/images/favicon/favicon-active.jpg",
        "New Guest Waiting"
      );
      favicon.animate(
        [
          "/images/favicon/favicon-32x32.png",
          "/images/favicon/favicon-inactive.jpg",
        ],
        250
      );
      setTimeout(() => {
        favicon.change("/images/favicon/favicon-active.jpg", "Child. Inc");
        favicon.stopAnimate();
      }, 10000);
    },
    setupPresenceOnlineUsers() {
      const presenceOnlineUsers = window.Echo.join("onlineUsers");
      presenceOnlineUsers.here((users) => {
        console.log("presence onlineUsers");
        console.log(users);
      });
    },
    resetPresenceOnlineUsers() {
      window.Echo.leave("onlineUsers");
    },
    resetEchoListener() {
      const channelGuestList = window.Echo.channel("guestList");
      channelGuestList.stopListening(".App\\Events\\GuestRegistered");
    },
    setupEchoListener() {

      const channelGuestList = window.Echo.channel("guestList");
      channelGuestList.listen(".App\\Events\\GuestRegistered", (e) => {
        this.$store.dispatch("guests/guestDirectAdd", {
          data: e.guest,
        });

        this.$snotify.warning("New Guest Waiting", "New Guest", {
          timeout: 0
        });

        this.audioMessage.play();
        this.updateFavicon();
        
      });

      channelGuestList.listen(".App\\Events\\AdminStartChat", (e) => {
        this.$store.dispatch("guests/guestDirectDelete", {
          data: e.guest,
        });
      });
      
    },
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true);
    },
    handleOpen(selectedTaskId) {
      this.selectedTaskId = selectedTaskId;
      this.$refs.taskEditModal.show();
    },
    loadUnfinishedChat() {
      this.$store.dispatch("chats/adminUnfinishedChat");
    },
  },

  computed: {
    ...mapState("chats", ["chatUnfinished"]),
    user: function () {
      return this.$store.getters["auth/user"];
    },
  },
};
</script>
