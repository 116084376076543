import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import auth from "./modules/auth"
import chats from "./modules/chats"
import users from "./modules/users"
import questions from "./modules/questions"
import guests from "./modules/guests"
import publicChats from "./modules/publicChats"


export default new Vuex.Store({
    modules: {
        auth: auth,
        chats: chats,
        questions: questions,
        users: users,
        guests: guests,
        publicChats: publicChats
    },
    plugins: [createPersistedState()]
});
