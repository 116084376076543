var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-wrapper layout-1" },
    [
      _c("vue-snotify"),
      _vm._v(" "),
      _vm.user
        ? _c(
            "div",
            { staticClass: "layout-inner" },
            [
              _c("app-layout-navbar"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-container" },
                [
                  _c("app-layout-sidenav"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-content" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "router-transitions container-fluid flex-grow-1 container-p-y"
                        },
                        [
                          _vm.chatUnfinished &&
                          _vm.chatUnfinished.guest &&
                          _vm.$route.name != "guest-chat"
                            ? _c(
                                "div",
                                { staticClass: "alert alert-success" },
                                [
                                  _c("h5", { staticClass: "alert-heading" }, [
                                    _vm._v("Unfinished chat")
                                  ]),
                                  _vm._v(
                                    "\n            You still have unfinished chat waiting.\n            "
                                  ),
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "guest-chat",
                                          params: {
                                            id: _vm.chatUnfinished.guest.id
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Click here to go to chat page.")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("router-view")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("app-layout-footer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "layout-overlay",
        on: { click: _vm.closeSidenav }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }