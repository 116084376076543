import axios from "axios";

const state = {
    roles: [],
    permissions: [],
    options: [],
    user: null,
    language: "en",
    loggedIn: false,
    errors: null
};

const getters = {
    options: state => state.options,
    permissions: state => state.permissions,
    roles: state => state.roles,
    loggedIn: state => !!state.token,
    user: state => state.user,
    language: state => state.language,
    errors: state => state.errors
};

const actions = {
    async profileInfo({ commit }) {
        let self = this;
        return axios
            .get("/api/users/profile")
            .then(result => {
                let languageOpt = result.data.options.find(option => option.option_name == 'language') 
                let language = 'en';
                if(languageOpt){
                    language = languageOpt.option_value
                }
                commit("authSuccess", {
                    language: language,
                    roles: result.data.roles,
                    permissions: result.data.permissions,
                    options: result.data.options,
                    user: result.data.user
                });
                window.ability.update(result.data.permissions)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async updateProfileInfo({ commit }, profileData) {
        const multiPartUploadConfig = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        return axios
            .post("/api/users/profile", profileData, multiPartUploadConfig)
            .then(result => {
                commit("authSuccess", {
                    user: result.data.user,
                    roles: result.data.roles,
                    permissions: result.data.permissions,
                    options: result.data.options
                });

            })
            .catch(err => {
                commit("updateProfileFailed", {
                    message: err.response.data.message
                });
            });
    },
    async login({ commit }, credentials) {
        return axios
            .post("/api/login", {
                email: credentials.email,
                password: credentials.password
            })
            .then(result => {
                let languageOpt = result.data.options.find(option => option.option_name == 'language') 
                let language = 'en';
                if(languageOpt){
                    language = languageOpt.option_value
                }

                commit("authSuccess", {
                    language: language,
                    user: result.data.user,
                    roles: result.data.roles,
                    permissions: result.data.permissions,
                    options: result.data.options
                });

                window.ability.update(result.data.permissions)
                localStorage.setItem('permissions', JSON.stringify(result.data.permissions));

            })
            .catch(err => {
                commit("authFailed", {
                    message: err.response.data.message
                });
            });
    },
    logout({ commit }) {
        return axios
            .post("/api/logout")
            .then(() => {
                commit("authLogout")
                window.ability.update([])
                localStorage.clear()
                window.location.href = "/admin";
            })
            .catch(err => {
                console.log(err)
            });
    }
};

const mutations = {
    authSuccess(state, data) {
        state.user = data.user;
        state.roles = data.roles;
        state.permissions = data.permissions;
        state.options = data.options;
        state.language = data.language;
        state.loggedIn = true;
        state.errors = null;
    },
    authFailed(state, data) {
        state.user = null;
        state.roles = null;
        state.permissions = null;
        state.options = null;
        state.loggedIn = false;
        state.errors = data.message;
    },
    authLogout(state) {
        state.loggedIn = false;
        state.errors = null;
    },
    updateProfileFailed(state, data) {
        state.errors = data.message;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
