import axios from 'axios';

const state = {
    userList: [],
    userListStats: {
        totalRows: 0,
        currentPage: 1,
        perPage: 10
    },
    userDetail: [],
    userStats: {}
};
const getters = {
    userListStats: state => state.userListStats, 
    userList: state => state.userList,
    userDetail: state => state.userDetail,
    userStats: state => state.userStats 
};

const actions = {
    async userList({ commit, state }, payload) {
        
        const params = {
            page : state.userListStats.currentPage
        };
        const urlParams = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');

        let response = await axios.get(`/api/users?${urlParams}`)
        let results = response.data.data;
        commit("setUserList", results);
        commit("setUserListStats", {
            totalRows: response.data.total,
            currentPage: response.data.current_page,
            perPage: response.data.per_page
        });
    },
    async userDetail({ commit }, payload) {
        let response = await axios.get(`/api/users/${payload.id}`)
        let detail = response.data.data
        commit("setUserDetail", detail);
    },
    async userStats({ commit }, payload) {
        let response = await axios.get(`/api/users/stats`)
        let detail = response.data
        commit("setUserStats", detail);
    },
    async userUpdate({commit}, payload){
        return await axios.put(`/api/users/${payload.id}`,payload.data)
    },
    async userCreate({commit}, payload){
        return await axios.post(`/api/users`,payload.data)
    },
    async userDelete({commit}, payload){
        await axios.delete(`/api/users/${payload.id}`)
        commit("deleteUser",{ id: payload.id })
    }
};

const mutations = {
    setUserList(state, payload) {
        state.userList = payload
    },
    setUserListStats(state, payload){
        state.userListStats = payload;
    },
    setUserDetail(state, payload) {
        state.userDetail = payload
    },
    setUserStats(state, payload) {
        state.userStats = payload
    },
    deleteUser(state, payload){
        let index = state.userList.findIndex(user => user.id == payload.id)
        state.userList.splice(index, 1)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
