import Vue from "vue";
import Vuex from "vuex";
import App from "./App";
import router from "./router";
import i18n from './i18n'

import BootstrapVue from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";

import globals from "./globals";
import store from "./store";
import Popper from "popper.js";
import "nprogress/nprogress.css";
import Snotify from "vue-snotify";
import VueMoment from "vue-moment";
import moment from "moment-timezone";

import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/themes/material_red.css';
Vue.use(VueFlatPickr);

import Echo from 'laravel-echo';
window.io = require('socket.io-client');

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname + ':6001'
});

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = true;

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(Snotify, {
    toast: {
        timeout: 6000,
        position: "rightTop",
        pauseOnHover: false
    }
});

Vue.use(VueMoment, {
    moment
});

Vue.mixin({
    data: globals
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
