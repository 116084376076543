var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidenav",
    { class: _vm.curClasses, attrs: { orientation: _vm.orientation } },
    [
      _c(
        "div",
        {
          staticClass: "sidenav-inner",
          class: { "py-1": _vm.orientation !== "horizontal" }
        },
        [
          _c(
            "sidenav-router-link",
            {
              attrs: {
                icon: "fas fa-tachometer-alt",
                to: { name: "dashboard" },
                exact: true
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("sidebar.dashboard")) + "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "sidenav-router-link",
            {
              attrs: {
                icon: "far fa-comments",
                to: { name: "guest-list" },
                exact: true
              }
            },
            [_vm._v("\n      Guests\n    ")]
          ),
          _vm._v(" "),
          _c("sidenav-divider", { staticClass: "mb-1" }),
          _vm._v(" "),
          _vm.roles.includes("Admin") ||
          _vm.ability.can("read", "User") ||
          _vm.ability.can("update", "User") ||
          _vm.ability.can("create", "User") ||
          _vm.ability.can("delete", "User")
            ? _c(
                "div",
                [
                  _c(
                    "sidenav-header",
                    { staticClass: "small font-weight-semibold" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("sidebar.administration")) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.roles.includes("Admin") ||
                  _vm.ability.can("read", "User") ||
                  _vm.ability.can("update", "User")
                    ? _c(
                        "sidenav-menu",
                        {
                          attrs: {
                            icon: "ion ion-ios-people",
                            active: _vm.isMenuActive("/admin/user"),
                            open: _vm.isMenuOpen("/admin/user")
                          }
                        },
                        [
                          _c("template", { slot: "link-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("sidebar.users")) +
                                "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.ability.can("read", "User")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: {
                                    to: { name: "user-list" },
                                    exact: true
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("sidebar.view-users")) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("create", "User")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: {
                                    to: { name: "user-create" },
                                    exact: true
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("sidebar.add-users")) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.roles.includes("Admin") ||
                  _vm.ability.can("read", "Question") ||
                  _vm.ability.can("update", "Question")
                    ? _c(
                        "sidenav-menu",
                        {
                          attrs: {
                            icon: "fas fa-question-circle",
                            active: _vm.isMenuActive("/admin/question"),
                            open: _vm.isMenuOpen("/admin/question")
                          }
                        },
                        [
                          _c("template", { slot: "link-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("sidebar.questions")) +
                                "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.ability.can("read", "Question")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: { to: "/admin/questions", exact: true }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("sidebar.view-questions")) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("create", "Question")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: {
                                    to: { name: "question-create" },
                                    exact: true
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("sidebar.add-questions")) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }