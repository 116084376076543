const state = {
    questionList: [],
    questionDetail: [],
    questionStats: {}
};
const getters = {
    questionList: state => state.questionList,
    questionDetail: state => state.questionDetail,
    questionStats: state => state.questionStats 
};

const actions = {
    async questionList({ commit, state }, payload) {
        let response = await axios.get(`/api/questions`)
        let results = response.data.data;
        commit("setQuestionList", results);
    },
    async publicQuestionList({ commit, state }, payload) {
        let response = await axios.get(`/api/public-questions`)
        let results = response.data.data;
        commit("setQuestionList", results);
    },
    async questionDetail({ commit }, payload) {
        let response = await axios.get(`/api/questions/${payload.id}`)
        let detail = response.data.data
        commit("setQuestionDetail", detail);
    },
    async questionStats({ commit }, payload) {
        let response = await axios.get(`/api/questions/stats`)
        let detail = response.data
        commit("setQuestionStat", detail);
    },
    async questionUpdate({commit}, payload){
        return await axios.put(`/api/questions/${payload.id}`,payload.data)
    },
    async questionCreate({commit}, payload){
        return await axios.post(`/api/questions`,payload.data)
    },
    async questionDelete({commit}, payload){
        await axios.delete(`/api/questions/${payload.id}`)
        commit("deleteQuestion",{ id: payload.id })
    }
};

const mutations = {
    setQuestionList(state, payload) {
        state.questionList = payload
    },
    setQuestionDetail(state, payload) {
        state.questionDetail = payload
    },
    setQuestionStat(state, payload) {
        state.questionStats = payload
    },
    deleteQuestion(state, payload){
        let index = state.questionList.findIndex(question => question.id == payload.id)
        state.questionList.splice(index, 1)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
