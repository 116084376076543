const state = {
    guestList: [],
    guestDetail: [],
    guestStats: {}
};
const getters = {
    guestList: state => state.guestList,
    guestDetail: state => state.guestDetail,
    guestStats: state => state.guestStats
};

const actions = {
    async guestList({ commit }) {
        let response = await window.axios.get(`/api/guests`)
        let results = response.data.data;
        commit("setGuestList", results);
    },
    async guestDetail({ commit }, payload) {
        let response = await window.axios.get(`/api/guests/${payload.id}`)
        let detail = response.data.data
        commit("setGuestDetail", detail);
    },
    async guestStats({ commit }) {
        let response = await window.axios.get(`/api/guests/stats`)
        let detail = response.data
        commit("setGuestStats", detail);
    },
    async guestUpdate({ commit },payload) {
        return await window.axios.put(`/api/guests/${payload.id}`, payload.data)
    },
    async guestCreate({ commit },payload) {
        return await window.axios.post(`/api/guests`, payload.data)
    },
    async guestDelete({ commit }, payload) {
        await window.axios.delete(`/api/guests/${payload.id}`)
        commit("deleteGuest", { id: payload.id })
    },
    async guestDirectSet({commit}, payload){
        commit("setGuestDetail", payload.data);
    },
    async guestDirectAdd({commit}, payload){
        commit("addGuest",payload.data);
    },
    async guestDirectDelete({commit}, payload){
        commit("deleteGuest",payload.data);
    }
};

const mutations = {
    setGuestList(state, payload) {
        state.guestList = payload
    },
    setGuestDetail(state, payload) {
        state.guestDetail = payload
    },
    setGuestStat(state, payload) {
        state.guestStats = payload
    },
    deleteGuest(state, payload) {
        let index = state.guestList.findIndex(guest => guest.id == payload.id)
        state.guestList.splice(index, 1)
    },
    addGuest(state, payload) {
        state.guestList.push(payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
