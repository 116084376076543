import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NProgress from 'nprogress';

import globals from '@/globals'

// import adminProfileRoutes from './profile'
// import adminQuestionRoutes from './questions'
// import adminGuestRoutes from './guests'

import LayoutBlank from "@/layout/LayoutBlank";
import LayoutBlankChat from "@/layout/LayoutBlankChat";
import LayoutAdmin from "@/layout/Layout1";


Vue.use(Router)
Vue.use(Meta)


const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/', component: LayoutBlankChat,
      children: [
        {
          path: '',
          name: 'landing-page',
          component: () => import('@/components/publicPage/LandingPage')
        },
        {
          path: 'chat-advocate',
          name: 'chat-advocate',
          component: () => import('@/components/publicPage/ChatAdvocate')
        }
      ]
    },
    {
      // Blank layout
      path: '/admin',
      component: LayoutBlank,
      children: [
        {
          path: '',
          name: 'admin-login',
          component: () => import('@/components/authentication/AuthenticationLoginV1')
        }
      ]
    },
    {
      // Blank layout
      path: '/admin/',
      component: LayoutAdmin,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import("@/components/dashboard/Dashboard")
        },
        {
          path: "/",
          name: "profile",
          component: () => import("@/components/profile/UserProfile")
        },
        {
          name: 'guest-list',
          path: "guests",
          component: () => import("@/components/guests/GuestList")
        },
        {
          name: 'guest-chat',
          path: 'guests/:id/chat',
          props: true,
          component: () => import("@/components/guests/GuestChat")
        },
        {
          name: 'user-list',
          path: "users",
          component: () => import("@/components/users/UserList")
        },
        {
          name: 'user-create',
          path: "users/create",
          props: true,
          component: () => import("@/components/users/UserForm")
        },
        {
          name: 'user-edit',
          path: "users/:id/edit",
          props: true,
          component: () => import("@/components/users/UserForm")
        },
        {
          name: 'question-list',
          path: "questions",
          component: () => import("@/components/questions/QuestionList")
        },
        {
          name: 'question-create',
          path: "questions/create",
          props: true,
          component: () => import("@/components/questions/QuestionForm")
        },
        {
          name: 'question-edit',
          path: "questions/:id/edit",
          props: true,
          component: () => import("@/components/questions/QuestionForm")
        }
      ]
    }
  ]
})

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }

  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)

  NProgress.done()
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (window.ability.rules.length <= 0) {
    let permissions = JSON.parse(localStorage.getItem('permissions'))
    window.ability.update(permissions)
  }

  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
